import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { ReactComponent as Logo } from "../../svgs/logo.svg";
import { ReactComponent as Slogan } from "../../svgs/slogan.svg";
import { ReactComponent as LogoSlogan } from "../../svgs/logo-slogan.svg";

const component = {
  logo: Logo,
  slogan: Slogan,
  "logo-slogan": LogoSlogan,
};

const height = {
  logo: css`
    height: 80px;
  `,
  slogan: css`
    height: 130px;
  `,
  "logo-slogan": css`
    height: 130px;
  `,
};

const StyledSVG = styled.svg`
  fill: currentColor;
  stroke: currentColor;
  overflow: visible;
  ${({ variant }) => height[variant]};
`;

const Brand = ({ variant = "logo", className }) => (
  <StyledSVG className={className} as={component[variant]} variant={variant} />
);

Brand.propTypes = {
  /**
   * The type of branding to show.
   */
  variant: PropTypes.oneOf(Object.keys(component)),
};

export default Brand;
